.overviewEmptyState {
    height: 474px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    border: solid 1px var(--color-gray-200);
    border-radius: 6px;
    padding: 80px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 30vw;
    background-image: url('../../images/empty_state_background.png');
    position: relative;
    transition: opacity 0.5s ease-in-out;
}

.dismiss-button {
    position: absolute;
    top: 16px;
    right: 24px;
    height: 24px;
    width: 24px;
    color: var(--color-gray-500);
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.overviewEmptyStateHeader {
    margin-top: 24px;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32.78px;
    text-align: left;

}

.overviewEmptyStateSubheader {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
}

.overviewEmptyStateDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}

.overviewEmptyStateContentLeftContainer {
    width: 50%;
    margin-left: 30%;
}

.overviewEmptyStateContentLeft {
    text-align: left;
    width: 384px;
}

.overviewEmptyStateContentRight {
    width: 50%;
    margin-right: 30%;
    text-align: center;
}