.button {
  padding: 10px;
}

.role {
  border: solid 1px darkgrey;
  color: darkgrey;
  font-size: 8pt;
  margin-left: 5px;
  padding: 2px;
}

.table .users-table {
  table-layout: fixed;
  width: 100%;
}

.table .users-table th,
.table .users-table td {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table .users-table th:nth-child(1),
.table .users-table td:nth-child(1) {
  width: 300px;
}

.table .users-table th:nth-child(2),
.table .users-table td:nth-child(2) {
  width: 160px;
}

.table .users-table th:nth-child(4),
.table .users-table td:nth-child(4) {
  width: 56px;
  padding: 0px;
  padding-right: 16px;
}

.devices-header {
  display: flex;
  flex-direction: row;
}

@media (max-width: 991px) {
  .table .users-table {
    min-width: 100%;
  }

  .devices-header {
    flex-direction: column;
    gap: 8px;
  }

  .table .users-table th:nth-child(1),
  .table .users-table td:nth-child(1) {
    width: 150px;
  }

  .table .users-table th:nth-child(2),
  .table .users-table td:nth-child(2) {
    width: 40px !important;
  }

  .table .users-table th:nth-child(3),
  .table .users-table td:nth-child(3) {
    display: none;
  }

  .table .users-table th:nth-child(3),
  .table .users-table td:nth-child(3) {
    white-space: normal;
    overflow: visible;
  }

  .table .users-table th:nth-child(4),
  .table .users-table td:nth-child(4) {
    width: 40px;
    padding-right: 12px;
  }


  .filter-bar, .left-top-bar {
    flex-direction: column !important;
  }

  .search-bar {
    width: 100% !important;
  }

  .filter-menu-container {
    width: 100%;
    justify-content: start;
    margin-bottom: 16px;
  }
}

.filter-menu-container {
  display: flex;
  gap: 8px;
}

.MuiButton-root.filter-menu {
  border-color: var(--color-border);
  color: var(--color-gray-900);
  font-weight: 500;
}

.MuiButton-root.filter-menu:hover {
  background-color: var(--color-gray-200);
  border-color: var(--color-border);
  text-decoration: none;
}

.admin-crown {
  height: 16px;
  width: 16px;
  display: inline-block;
}

.left-top-bar {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: row;
}

.fullname {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: var(--color-gray-900);
}

.email {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--color-gray-500);
}

.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-gray-200);
  border-radius: 6px;
  width: 320px;
  height: 44px;
  padding-left: 14px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
  height: 100%;
  padding: 0 8px;
  font-size: 16px;
  border-radius: 6px;
}

.search-icon {
  width: 20px;
  height: 20px;
  color: var(--color-gray-500);
}

.MuiButton-sizeMedium.filter-menu {
  border: 1px solid var(--color-gray-200);
  border-radius: 6px;
  height: 44px;
  padding: 0px 16px;
}

.MuiMenu-list {
  padding-bottom: 0 !important;
}

.MuiMenuItem-gutters.filter-menu-item {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #393e45;
  background-color: white;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  flex-direction: row;
}

.filter-bar-content {
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: left;
  color: var(--color-gray-500);
}

.filter-bar-content-numbers {
  font-size: 14px;
  font-weight: 600;
  line-height: 19.12px;
  text-align: left;
  color: var(--color-gray-900);
}

.invite-buttons {
  display: flex;
  gap: 8px;
  margin-left: auto;
}

.selectableRow:hover {
  background-color: var(--color-gray-100);
}