/* styles.css */
:root {
  --color-gray-50: #f9fafb;
  --color-gray-100: #F3F4F6;
  --color-gray-200: #e2e4e8;
  --color-gray-300: #CCD0D6;
  --color-gray-400: #9BA1AB;
  --color-gray-500: #6D717A;
  --color-gray-600: #4B5057;
  --color-gray-700: #393E45;
  --color-gray-800: #21252B;
  --color-gray-900: var(--color-gray-900);
  --color-background-light: var(--color-gray-50);
  --color-border: #D9DCE0;
  --color-header: var(--color-gray-100);
  --color-background-dark: var(--color-gray-200);
  --color-green: #12b981;
  --color-green-600: #059669;
  --color-red: #dc2626;
  --color-yellow: #fbbf24;
  --color-yellow-light: #fffbeb;
  --color-yellow-dark: #92400E;
}