@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./colors.css";

body {
  background-color: white !important;
  margin: 0;
  font-family: Manrope;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiTableContainer-root {
  border: solid 1px var(--color-gray-200);
  box-shadow: none !important;
}
