#loginBackground, #signupBackground {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-100);
  background-size: cover;
  background-position: 100% 70%;
  background-repeat: no-repeat;
}

#signupContent {
  width: 100%;
  height: min-content;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
  background-size: cover;
  background-position: 100% 70%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#signupContainer {
  width: 100%;
  min-height: 100vh;
  height: max-content;
  background-color: white;
  background-size: cover;
  background-position: 100% 70%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
}

#darksidebar {
  background-color: var(--color-gray-100);
  display: flex;
  max-width: 100vw;
  min-width: 40%;
  flex-direction: column;
}

#signupSidebarContent {
  height: 100%;
  display: flex;
  justify-content: left;
  flex-direction: column;
  margin-top: 48px;
  margin-left: 48px;
}

#signupForm {
  width: min(460px, calc(100% - 96px));
  margin-left: auto;
  margin-right: auto;
}

#sidebar {
  display: flex;
  background-color: white;
  max-width: 100vw;
  min-width: 40%;
  flex-direction: column;
}

#sidebarFooter {
  margin: 16px;
}

#sidebarSignupFooter {
  margin-bottom: 24px;
  margin-left: 42px;
}

#sidebarContent {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#form {
  width: 450px;
  padding: 30px 50px;
}

#fields {
  margin-top: 26px;
}

.discovery-error {
  margin-top: 26px;
}

#loginForm #password,
#loginForm #errorMessage, #errorMessage {
  margin-top: 12px;
}

#loginForm #recoveryCode {
  margin-top: 30px;
}

#loginForm .forgotPassword {
  margin-top: 5px;
  padding-left: 14px;
  font-size: 0.8em;
}

#loginForm #options {
  margin-top: 12px;
  display: grid;
  flex-direction: row;
}

#forgotPassword {
  justify-self: flex-end;
  color: var(--var-color-900);
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
}

.sendButton {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

#login-button {
  width: 100%;
  text-transform: initial;
  font-weight: 600;
}

#languageSelector {
  display: flex;
  font-size: 0.9em;
  align-items: center;
  color: #666;
  justify-content: flex-start;
  width: 100%;
  margin: 18px;
}

.spinnerCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#signup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
}

#username {
  width: 100%;
}

.topPart {
  height: 25%;
  margin-left: 110px;
  margin-right: 110px;
}

.bottomPart {
  height: 75%;
  margin-left: 30px;
}

.quote {
  margin-top: 140px;
  margin-bottom: 34px;
  font-size: 28px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
  color: var(--color-gray-900);
}

.quotePerson {
  font-size: 18px;
  font-weight: 700;
  line-height: 24.59px;
  text-align: left;
  color: var(--color-gray-900);
}

.quoteTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.12px;
  text-align: left;
  color: var(--color-gray-500);
}

.quoteContact {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.quoteText {
  display: flex;
  flex-direction: column;
}

.quotePerson,
.quoteTitle {
  margin: 0;
}

.quoteStars {
  height: 25px;
}

.stepsContainer {
  margin-top: 98px;
}

.signupStep {
  display: flex;
  align-items: center;
  height: 44px;
}

.signupStep.active {
  color: var(--color-gray-900);
  font-weight: bold;
}

.stepImage {
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 6px;
  margin-right: 18px;
  color: var(--color-gray-400);
  opacity: 0.4;
}

.stepImage.active {
  opacity: 1;
  color: var(--color-gray-900);
}

.stepDetails {
  flex: 1;
}

.stepTitle.active {
  color: var(--color-gray-900);
}

.stepTitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: var(--color-gray-400);
}

.stepDescription {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: var(--color-gray-400);
}

.verticalDivider {
  width: 2px;
  height: 42px;
  margin-left: 22px;
  background-color: var(--color-border);
}

.verticalDividerWithoutBorder {
  width: 2px;
  height: 42px;
  margin-left: 22px;
  background-color: transparent;
}

.generalConditions {
  color: var(--color-gray-500);
  font-size: 14px;
  margin-top: 4px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.input-container, #card-holder, #expiry-date, #verification-code, #card-number {
  border: 1px solid var(--color-gray-500);
  border-radius: 6px;
  padding: 12px;
}

iframe {
  height: 20px;
}

.login-right-part {
  display: block;
}

@media (max-width: 991px) {
  #darksidebar {
    display: none;
  }

  .login-right-part {
    display: none;
  }

  #sidebar {
    min-width: 100%;
    height: max-content;
  }

  #sidebarContent {
    align-items: start;
    height: max-content;
  }

  #signupContainer {
    height: max-content;
    padding-top: 24px;
  }
}