.highlight-card {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    padding: 24px;
    width: 100%;
}

.highlight-header {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 4px;
}

.highlight-title {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0.09em;
    text-align: left;
    color: var(--color-gray-500);
    text-transform: uppercase;
    margin-right: 8px;
}

.highlight-icon {
    cursor: pointer;
    width: 12px;
    height: 12px;
}

.highlight-total {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--color-gray-900);
    margin-bottom: 22px;
}

.highlight-progress-bar-container {
    display: flex;
    height: 12px;
    border-radius: 6px;
    overflow: hidden;
    background-color: #e0e0e0;
    margin-bottom: 16px;
    margin-top: 16px;
}

.highlight-progress-bar {
    height: 100%;
}

.highlight-enabled-bar {
    background-color: var(--color-green-500);
}

.highlight-disabled-bar {
    background-color: var(--color-gray-300);
}

.highlight-not-encrypted-bar {
    background-color: var(--color-red);
}

.highlight-legend {
    display: flex;
    justify-content: start;
}

.highlight-legend-container {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
}

.highlight-legend-item {
    display: flex;
    align-items: center;
    text-align: left;
    margin-right: 8px;
}

.highlight-legend-color {
    width: 12px;
    height: 12px;
    border-radius: 3px;
    margin-right: 6px;
}

.highlight-enabled-color {
    background-color: #33c381;
}

.highlight-disabled-color {
    background-color: var(--color-gray-300);
}

.highlight-not-encrypted-color {
    background-color: var(--color-red);
}

.highlight-legend-total {
    color: var(--color-gray-900);
    margin-right: 4px;
}

.highlight-legend-percentage {
    color: var(--color-gray-500);
}