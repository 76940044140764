.button {
    padding: 10px;
}

.role {
    border: solid 1px darkgrey;
    color: darkgrey;
    font-size: 8pt;
    margin-left: 5px;
    padding: 2px;
}

.table {
    table-layout: fixed;
    width: 100%;
}

.table th,
.table td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.table th:nth-child(1),
.table td:nth-child(1) {
    min-width: max-content;
    max-width: 300px;
    width: 20%;
}

.table th:nth-child(2),
.table td:nth-child(2) {
    width: 130px;
}

.table th:nth-child(3),
.table td:nth-child(3) {
    white-space: normal;
    overflow: visible;
    width: 60%;
}

.table th:nth-child(4),
.table td:nth-child(4) {
    width: 80px;
}

.admin-crown {
    height: 16px;
    width: 16px;
    display: inline-block;
}

.left-top-bar {
    display: flex;
    align-items: center;
    gap: 16px;
}

.fullname {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-900);
}

.email {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-500);
}

.search-bar {
    display: flex;
    align-items: center;
    border: 1px solid var(--color-gray-200);
    border-radius: 6px;
    width: 320px;
    height: 44px;
    padding-left: 14px;
}

.search-input {
    border: none;
    outline: none;
    flex-grow: 1;
    height: 100%;
    padding: 0 8px;
    font-size: 16px;
    border-radius: 6px;
}

.search-icon {
    width: 20px;
    height: 20px;
    color: var(--color-gray-500);
}

.filter-bar {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
}

.filter-bar-content {
    display: flex;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.12px;
    text-align: left;
    color: var(--color-gray-500);
}

.filter-bar-content-numbers {
    font-size: 14px;
    font-weight: 600;
    line-height: 19.12px;
    text-align: left;
    color: var(--color-gray-900);
}

.invite-buttons {
    display: flex;
    gap: 8px;
    margin-left: auto;
}

.selectableRow:hover {
    background-color: var(--color-gray-100);
}

.testtest {
    padding-left: 0 !important;
}