.button {
    padding: 10px;
}

.overview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    gap: 8px;
    flex-wrap: wrap;
    margin-top: 8px;
    width: 100%;
}

@media (min-width: 2195px) {
    .overview.device-checks {
        grid-template-columns: repeat(4, 1fr);
    }
}


.statistic {
    display: flex;
    max-width: 445px;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border: 1px solid var(--color-border);
    border-radius: 6px !important;
    padding: 24px;
    box-shadow: none !important;
}

.statisticWithTitle {
    padding: 0px;
}

.overview-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    width: 100%;
}

@media (max-width: 991px) {
    .overview-header {
        flex-direction: column;
        gap: 8px;
    }
}

.statisticContainer {
    display: block;
    flex: 1;
    box-shadow: none !important;
    background-color: transparent !important;
    border: 1px solid var(--color-border);
    border-radius: 6px !important;
    padding: 24px;
    height: 464px !important;
}

.statisticLinkContainer {
    display: block;
    height: 464px !important;
}

@media (max-width: 991px) {
    .statisticContainer, .statisticLinkContainer {
        width: 100%;
        height: 500px !important;
    }

    .healthLegendContainer {
        margin-top: 64px !important;
    }
}

.statisticCombo {
    display: flex;
    width: 100%;
}

.statisticsDescription {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #878787;
}

.statisticTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--color-gray-900);
}

.statisticContent {
    height: 100%;
    display: flex;
    width: 100%;
    padding-bottom: 24px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    font-size: 14px;
    color: var(--color-gray-500);
}

.users {
    width: 360px;
}

.devices {
    width: 340px;
}

.uptodate {
    width: 520px;
}

.browsers {
    width: 900px;
    max-width: unset;
}

.usageGraph {
    width: 100%;
    height: 220px;
}

.usageGraphPlaceholder {
    width: 100%;
    height: 100%;
    justify-content: center;
}

.diskencryption {
    width: 520px;
}

.metric {
    display: flex;
}

.metricNumber {
    height: 100%;
}

.metricLabel {
    padding-left: 5px;
    display: flex;
    height: 100%;
    align-items: flex-end;
}

.bigMetric {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.bigMetricNumber {
    font-size: 48px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 0px;
}

.bigMetricLabel {
    margin-top: 12px;
    display: flex;
    height: 100%;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #878787;

}

.get-started-overview-container {
    transition: opacity 0.5s ease-in-out;
}

.statistics {
    display: flex;
    flex-direction: row;
    /* Start with row direction */
    flex-wrap: wrap;
    /* Allow wrapping */
    align-items: flex-start;
    margin-bottom: 16px;
    max-width: 100%;
}

@media (min-width: 1650px) {
    .statistics {
        flex-direction: row;
    }
}

p.MuiTypography-root.MuiTypography-body2.deviceUsageLabel {
    height: 100%;
    align-content: center;
    padding-left: 4px;
}

li.recharts-legend-item span {
    color: var(--color-gray-700) !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.recharts-reference-line .recharts-label tspan {
    font-weight: 700;
    border-color: var(--color-gray-900) !important;
    color: var(--color-gray-900) !important;
}