.checkbox-container {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
}

.checkbox-input {
    appearance: none;
    margin-top: 4px;
    margin-right: 12px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: 2px solid var(--color-gray-200);
    border-radius: 4px;
    background-color: #fff;
    transition: background-color 0.2s, border-color 0.2s;
    position: relative;
}

.checkbox-input:checked {
    background-color: var(--color-gray-900);
    border-color: var(--color-gray-900);
}

.checkbox-input:checked::before {
    content: '';
    position: absolute;
    top: 0px;
    left: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox-content {
    display: flex;
    flex-direction: column;
}

.checkbox-label {
    font-size: 14px;
    font-weight: 600;
    line-height: 35px;
    text-align: left;
    color: var(--color-gray-700);
}

.checkbox-description {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-500);
}

.checkbox-container.disabled .checkbox-label,
.checkbox-container.disabled .checkbox-description {
    color: var(--color-gray-300);
}

.checkbox-container.disabled .checkbox-input {
    cursor: not-allowed;
    background-color: var(--color-gray-50);
    border-color: var(--color-gray-200);
}