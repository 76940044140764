.button {
  padding: 10px;
}

.applications {
  width: 360px;
}

.selectButton {
  width: 45%px;
  height: 128px;
}

.nested {
  padding-left: 30px;
}

.nested input {
  height: 0.75em;
  font-size: 0.75em;
  width: 35px;
}

.column {
  width: calc(50% - 4px);
}

.awarenessCard {
  margin-top: 16px;
  width: 50%;
  height: min-content;
}

@media (max-width: 1024px) {
  .column {
    width: 100%;
  }

  .awarenessCard {
    width: 100%;
  }
}

.section {
  border-radius: 6px;
  border: 1px solid var(--color-gray-200);
  padding: 24px;
}

.testSection {
  flex: 1;
}

.selectionSection {
  border-radius: 6px;
  border: 1px solid var(--color-gray-200);
  padding: 24px;
  width: 48%;
  display: inline-block;
  margin: 1%;
  vertical-align: top;
}

.verticalSelectionSection {
  border-radius: 6px;
  border: 1px solid var(--color-gray-200);
  padding: 24px;
  width: 100%;
  display: inline-block;
  vertical-align: top;
}

.emptyStateHeader {
  margin-top: 24px;
  margin-bottom: 8px;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 20px;
  text-align: center;
}

.emptyStateDescription {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}

.emptyStateContent {
  text-align: center;
}

.emptyStateIcon {
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}

.center {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}