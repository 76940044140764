.selection-button {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  transition: border-color 0.3s;
}

.selection-button-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.selection-button:hover {
  border-color: black;
}

.header:hover {
  border-bottom-color: black;
}

.header {
  background-color: var(--color-gray-50);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--color-border);
  padding: 10px;
}

.logo {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.title {
  font-size: 16px;
  color: var(--color-gray-900);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.description:hover {
  border-top-color: black;
}

.description {
  border-top: solid 1px var(--color-border);
  background-color: #fff;
  padding: 10px;
  color: var(--color-gray-500);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}