.details-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  text-align: left;
  color: var(--color-gray-900);
}

.status-chip-container {
  flex-shrink: 0;
}

.details-os {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.39px;
  text-align: left;
  color: var(--color-gray-700);
}

.details-card {
  border-radius: 6px !important;
  margin-bottom: 16px;
}

.details-card-content {
  padding: 24px;
}

.details {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
}

.details-last-active-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 19.12px;
  text-align: left;
  color: var(--color-gray-700);
}

.details-last-active {
  font-size: 12px;
  font-weight: 400;
  line-height: 16.39px;
  text-align: left;
  color: var(--color-gray-700);
}

.details-last-active-container {
  display: flex;
  gap: 16px;
}

.details-last-active-found {
  align-content: center;
  display: flex;
  gap: 8px;
  align-items: center;
}

.unverified-label {
  height: 51px;
  background-color: var(--color-gray-100);
  display: flex;
  align-items: center;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19.12px;
  color: var(--color-gray-900);
}

.mfaResetButton {
  font-size: 14px;
}

.discovered-images {
  display: flex;
  gap: 8px;
  margin-left: 8px;
}