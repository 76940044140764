.noApplications {
    background-image: url('../../images/empty_state_background.png');
    max-height: 630px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin: 20px 0px;
    border: solid 1px var(--color-gray-200);
    border-radius: 6px;
    padding: 80px;
}

.emptyStateHeader {
    margin-top: 24px;
    margin-bottom: 8px;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 20px;
    text-align: center;
}

.emptyStateDescription {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
}

.emptyStateContent {
    text-align: center;
}

.emptyStateIcon {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
}