.discovery-logo-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90px;
    background-color: white;
    border-bottom: solid 1px var(--color-border);
}

.discovery-container-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 170px);
    height: max-content;
    padding: 16px;
    background-color: white;
}

.discovery-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 180px);
    min-height: max-content;
    padding: 16px;
    background-color: var(--color-gray-50);
}

.discovery-header {
    max-width: 840px;
    width: 100%;
    text-align: center;
    margin-bottom: 32px;
}

.discovery-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    color: var(--color-gray-900);
    margin-bottom: 8px;
}

.discovery-description {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    margin-bottom: 8px;
    color: var(--color-gray-500);
}

.discovery-subdescription {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;

    color: var(--color-gray-500);
}

.discovery-radio-group {
    width: min(840px, calc(100% - 32px));
}

.discovery-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 90px;
    background-color: white;
    border-top: solid 1px var(--color-border);
    bottom: 0px;
}

.discovery-method {
    display: flex;
    align-items: center;
}

.discovery-footer-buttons {
    width: min(840px, calc(100%-24px));
    display: flex;
    gap: 16px;
    justify-content: flex-end;
}

.identification-method {
    display: flex;
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    transition: background-color 0.3s ease;
    cursor: pointer;
}

.identification-method:hover {
    background-color: var(--color-gray-100);
}

.identification-method-icon {
    margin-right: 16px;
}

.identification-method-icon-image {
    width: 48px;
    height: 48px;
}

.identification-method-text-content {
    flex-grow: 1;
}

.identification-method-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-900);
}

.identification-method-subtitle {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-500);

}

.identification-method-arrow {
    font-size: 24px;
    color: #667085;
    transition: color 0.3s ease;
    margin-left: 24px;
}

.identification-method:hover .arrow {
    color: #101828;
}