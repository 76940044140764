.button {
  padding: 10px;
}

.address {
  font-size: 0.8em;
  color: #777;
}

.MuiTable-root.countTable {
  border-collapse: collapse;
  padding: 0;
  max-width: 100px;
  margin: 0;
}

.MuiTableCell-sizeMedium.countCellCenter {
  text-align: center;
  padding: 0 5px;
  font-size: 0.9em;
}

.MuiTableCell-sizeMedium.countCellLeft {
  text-align: left;
  padding: 0 5px;
  font-size: 0.9em;
}

.MuiTableCell-sizeMedium.countCellRight {
  font-weight: bold;
  text-align: right;
  padding: 0;
  font-size: 0.9em;
}
