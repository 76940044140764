.MuiSvgIcon-fontSizeMedium.icon, .icon {
    width: 16px;
    height: 16px;
    color: var(--color-gray-500);
}

.icon-delete {
    width: 16px;
    height: 16px;
    color: var(--color-red-700) !important;
}

.ul.MuiList-root.MuiMenu-list .resend-invite {
    margin-bottom: 0px !important;
}

span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0px;
}

.MuiButton-root.dropdown-button {
    border-color: var(--color-border);
    color: var(--color-gray-900);
}

.dropdown-button:hover {
    background-color: var(--color-gray-200);
    border-color: var(--color-border);
}

.dropdown-list-item-icon {
    width: 16px !important;
    min-width: 16px !important;
    height: 16px;
    margin-right: 12px;
}