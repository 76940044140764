.settings-section-header {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-700);
}

.settings-section-description {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-500);
}

.section-label {
    margin-top: 16px;
    width: 280px;
    margin-right: 28px;
}

.settings-section {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

@media (max-width: 991px) {
    .settings-section {
        flex-direction: column;
        align-items: start;
        max-width: 100%;
    }
}