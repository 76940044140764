.transaction-status-icon {
    width: 232x;
    height: 32px;
    padding: 4px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
    border: 1px solid var(--color-border);
}

.MuiPaper-elevation1.activityPaper {
    display: block;
    box-shadow: none;
    background-color: transparent;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 24px;
    margin-top: 24px;
    max-width: max(900px, 50%);
}

.activityListTitle {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0px;
    color: var(--color-gray-900);
    margin-bottom: 24px;
}

.activityItemEmail {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: var(--color-gray-900);
}

.activityItemStatus {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: var(--color-gray-600);
}

.blocked-tooltip {
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: var(--color-red-600);
    text-decoration: underline;
}

.chevron-right-icon {
    width: 10px;
    height: 10px;
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 2px;
}