.stage-card {
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 226px;
    height: 100%;
    background-color: white;
    flex: 1 1 0;
    min-width: 450px;
}

.stage-card.inactive {
    min-height: 224px;
}

.stage-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.recharts-default-legend {
    margin-right: -8px !important;
}

.stage-card .MuiTypography-h4 {
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-900);
}

.stage-card-header .MuiTypography-h6 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.stage-card-content {
    margin-bottom: auto;
    margin-top: auto;
}

.stage-card-content .MuiTypography-body2 {
    color: var(--color-gray-500);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

}

.stage-card-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 24px;
    gap: 8px;
}

.stage-card-footer .MuiButton-root {
    flex: 1 1 auto;
    white-space: nowrap;
    text-align: center;
    max-width: fit-content;
}

.discovery-device {
    height: 62px;
    background-color: var(--color-gray-100);
    border-radius: 12px;
    padding: 12px;
}

.discovery-device-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-700);
}

.discovery-device-description {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-500);
}

.discovery-device-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8px;
}

.left-content {
    display: flex;
    flex-direction: column;
}


.right-content {
    display: flex;
}

.stage-card-discovery-subtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 425px) {
    .stage-card-footer .MuiButton-root {
        min-width: 100%;
    }

    .stage-card {
        min-width: calc(100% - 24px);
    }

    .stage-card-dropdown {
        margin-top: 12px;
        margin-left: 2px;
    }

    .right-content {
        display: none;
    }
}