.MuiCard-root.free-trial-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 32px 24px;
  background-color: #f9fafb;
  margin-top: 32px;
  margin-bottom: 32px;
}

.MuiCardContent-root.card-content {
  padding: 0px !important;
}

.free-trial-top {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--color-gray-700);
}

.free-trial-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
  margin-top: 8px;
  color: var(--color-gray-900);
}

.free-trial-subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--color-gray-500);
}
