.root {
  flex-grow: 1;
}

.title {
  flex-grow: 1;
  text-align: left;
}

.username {
  padding-right: 5px;
  padding-left: 10px;
}

.logout {
  margin-right: 5px;
}

html,
body {
  overscroll-behavior-x: none;
}

.homeNavigation .MuiListItemButton-root:hover {
  background-color: var(--color-gray-200);
}

.homeNavigation .MuiListItemButton-root.Mui-selected {
  background-color: var(--color-gray-200);
}

.homeNavigation .MuiListItemButton-root.Mui-selected:hover {
  background-color: var(--color-gray-300);
}

li.MuiListItem-root.home-navigation-section-title-container {
  margin-top: 24px;
  padding-bottom: 8px !important;
  padding-top: 0;
}

.MuiListItemText-root.home-navigation-section-title .MuiTypography-root {
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0.1em;
  text-align: left;
  color: var(--color-gray-500) !important;
}


.navigationIcon {
  height: 18px;
  color: var(--color-gray-500);
}

.homeNavigation .MuiSvgIcon-root {
  font-size: 18px !important;
  color: var(--color-gray-500);
}

.homeNavigation .MuiListItemIcon-root {
  min-width: auto;
  margin-right: 8px;
}

.homeNavigation .MuiListItemButton-root {
  height: 40px;
  border-radius: 8px;
}

.homeNavigation .MuiList-root {
  margin-left: 8px;
  margin-right: 8px;
}

.homeAppBar {
  display: none;
}

.homeAppBar .MuiToolbar-root {
  display: none;
}

.dashboardContent {
  padding: 32px 32px 100px 32px;
}

@media (max-width: 991px) {
  .homeAppBar .MuiToolbar-root {
    display: inherit;
  }

  .homeAppBar {
    display: block;
    height: 50px;
    background-color: white !important;
    box-shadow: none !important;
  }

  .dashboardContent {
    max-width: 100%;
    margin-top: 50px;
    padding-left: 12px;
    padding-right: 12px;
  }
}