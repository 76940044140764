.editButton {
    margin-top: 15px !important;
}

.billingPapers {
    display: flex;
    flex-direction: row;
    /* Start with row direction */
    flex-wrap: wrap;
    gap: 24px;
    /* Allow wrapping */
    align-items: flex-start;
    margin-bottom: 16px;
    max-width: 100%;
    justify-content: space-between;
}

.MuiPaper-elevation1.billingPaperContainer, .statisticLinkContainer {
    flex: 1 1 calc(33% - 16px);
    /* Ensure a maximum of 3 components per line */
}


@media screen and (max-width: 1400px) {
    .MuiPaper-elevation1.billingPaperContainer, .statisticLinkContainer {
        flex: 1 1 calc(50% - 16px);
        /* Ensure a maximum of 3 components per line */
    }
}

@media screen and (max-width: 991px) {
    .billingPapers {
        flex-direction: column;
        /* Change to column direction */
    }
}

.MuiTypography-body1.billingPaperWithTitle {
    padding: 0;
}

.MuiPaper-elevation1.billingPaperContainer {
    display: block;
    box-shadow: none;
    background-color: transparent;
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 24px;
    min-height: 464px;
}

#billingAddress {
    margin-top: 20px;
    max-width: 500px;
}

.field {
    margin-top: 20px;
}

.MuiButton-disableElevation.editButton {
    margin-top: 24px;
    height: 40px;
    font-weight: 600;
    font-size: 14px;
    color: var(--color-gray-900);
    line-height: 19px;
    background-color: var(--color-gray-200);
}

.MuiButton-disableElevation.editButton:hover {
    background-color: var(--color-gray-300);
}

.MuiTypography-body1.paperTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 24px;
}

.MuiTypography-body1.paperContent {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--color-gray-500);
}

.MuiTypography-body1.paperContentBold {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: var(--color-gray-900);
}

.MuiTypography-body1.paperContentSmall {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: var(--color-gray-500);
}

@media (max-width: 991px) {
    .MuiPaper-elevation1.billingPaperContainer {
        min-width: 100%;
    }
}