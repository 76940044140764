.admin-container {
    display: flex;
    flex-direction: row;
}

.admin-settings-section {
    display: flex;
    width: 326px;
}

.table-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
}

.MuiButton-textSizeMedium.invite-button {
    align-self: flex-start;
    height: 40px;
    gap: 4px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-200);
    background-color: white;
    opacity: 1;
    margin-bottom: 16px;
    padding: 0px 16px;
}

.admin-table {
    min-width: 650px;
}

.fullname, .email {
    display: block;
}

.selectableRow {
    cursor: pointer;
    height: 72px;
}

.table-head {
    background: var(--color-gray-100);
}

.fullname, .email {
    display: block;
}

.MuiTableContainer-root.no-margin-top {
    margin-top: 0;
}

.circular-progress {
    margin-right: 10px;
    color: primary;
}


.inline-settings-header {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    color: var(--color-gray-900);
}

.inline-settings-description {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-500);
}

@media (max-width: 991px) {
    .admin-container {
        display: flex;
        flex-direction: column;
    }

    .admin-table {
        min-width: 100%;
    }
}