.risk-card {
    height: min-content;
    border-radius: 8px;
    flex: 1 1 0;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: var(--color-gray-100);
    border: 1px solid var(--color-border);
    min-width: 300px;
}

.skeleton {
    padding-bottom: 24px;
}

.risk-card--ok {
    background-color: var(--color-gray-100);
    border: 1px solid var(--color-border);
}

.risk-card--warning {
    background-color: var(--color-gray-100);
    border: 1px solid var(--color-border);
}

.risk-card--danger {
    background-color: #FEF2F2;
    border-color: #FECACA;
    color: #B91C1C !important;
}

.risk-card--danger .risk-card__description {
    color: #B91C1C !important;
}

.risk-card__header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.risk-card__header h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    display: flex;
}

.risk-card__header img {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 8px;
}

.risk-card__description {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--color-gray-500);
    margin-bottom: 16px;
    min-height: 80px;
}

@media (min-width: 1500px) {
    .risk-card__description {
        min-height: 60px;
    }
}

@media (min-width: 1710px) {
    .risk-card__description {
        min-height: 40px;
    }
}

.risk-card__checks {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.risk-card__check {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px;
    border-radius: 6px;
    min-height: 36px;
    border: 1px solid var(--color-border);
    color: var(--color-gray-700);
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    flex-direction: column;
}

.risk-card__subitems {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: left;
    margin-right: 24px;
    width: 100%;
}

.risk-card__subitem {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: flex;
    margin-left: 50px;
    margin-right: 24px;
    gap: 8px;
}

.risk-card__subitem:hover {
    font-weight: 700;
}

.risk-card__check--success {
    background-color: white;
    color: var(--color-gray-700);
    border: 1px solid var(--color-border);
}


.risk-card__check--warning {
    background-color: #FFFBEB;
    color: #92400E;
}

.risk-card__check--danger-primary {
    background-color: #B91C1C;
    color: #ffffff;
    border-color: transparent;
}

.risk-card__check--danger-secondary {
    background-color: #ffffff;
    color: #B91C1C;
    border-color: #FECACA;
}

.toggle-icon {
    cursor: pointer;
    transition: transform 0.05s ease;
    margin-left: auto;
    margin-right: 8px;
}

.toggle-icon.expanded {
    transform: rotate(90deg);
}