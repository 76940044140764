.MuiCard-root.period-selector {
    display: flex;
    flex-direction: row;
    width: 126px;
    justify-content: space-between;
    height: 28px;
    padding: 4px;
    gap: 4px;
    border-radius: 6px;
    border-width: 1px;
    border: 1px solid var(--color-border);
    box-shadow: 0px 1px 4px 0px #0000000D;

}

.MuiButton-colorPrimary.period-selector-button {
    width: 18px;
    min-width: 18px;
    max-width: 18px;
    height: 18px;
    padding: 0;
    border-radius: 50%;
    margin: 0px;
}

.period-selector-content {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    color: var(--color-gray-900);
}