.trial-cta-container {
    margin-top: 16px;
    min-height: 400px;
    padding: 16px;
    gap: 8px;
    border-radius: 12px;
    background-color: var(--color-gray-100);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

@media (max-width: 1300px) {
    .trial-cta-container {
        flex-direction: column;
        align-items: center;
    }
}

.trial-card {
    display: flex;
    flex-direction: column;
    min-height: 180px;
    padding: 24px;
    gap: 0px;
    border-radius: 6px;
    justify-content: space-between;
    border: 1px solid var(--color-border);
    background-color: white;
}

.MuiTypography-root.trial-card-title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-900);
    margin-bottom: 8px;
}

.MuiTypography-root.trial-card-description {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-500);
    margin-bottom: 20px;
}

.trial-progress-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 32px 16px;
    gap: 40px;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.trial-progress-container.small {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 8px;
    padding: 16px;
    width: auto;
    gap: 16px;
    height: auto;
    justify-content: center;
    border-radius: 6px;
    background-color: var(--color-gray-200);
}

.MuiTypography-root.trial-progress-header {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 8px;
    color: var(--color-gray-900);
}

.MuiTypography-root.trial-progress-header.small {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-bottom: 0px;
    color: var(--color-gray-900);
}

.MuiTypography-root.trial-progress-description {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-700);
}

.progress-container {
    width: 75%;
}

.progress-container.small {
    width: 100%;
}

.MuiLinearProgress-root.trial-progress-bar {
    height: 8px;
    border-radius: 6px;
    fill: #3B82F6;
}

.MuiTypography-root.trial-days-left {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    margin-top: 4px;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-700);
}

.trial-card {
    flex: 1 1 calc(50% - 8px);
    min-width: 200px;
}

.MuiButtonBase-root.cta-button.small {
    font-size: 12px;
}

.sources {
    width: 100%;
    font-size: 10px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-500);
}