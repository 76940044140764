.toast-notification {
    background-color: #ffffff;
    box-shadow: 0px 12px 16px -4px #00000010;
    border: 1px solid #d9dce0;
    display: flex;
    flex-direction: row;
    width: 400px;
    z-index: 1000;
    padding: 16px;
    border-radius: 6px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.toast-notification.show {
    animation: slideInRight 0.3s forwards;
}

.toast-notification.hide {
    animation: fadeOutUp 0.3s forwards;
}

.toast-notification.move-up {
    animation: moveUp 0.4s forwards;
}

@keyframes slideInRight {
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes fadeOutUp {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(-1200%);
        opacity: 0;
    }
}

@keyframes moveUp {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-110px);
    }
}

.toast-message-column {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-right: 10px;
}

.toast-icon-column {
    width: 20px;
    margin-right: 16px;
}

.button-container {
    display: flex;
    flex-direction: row;
    top: 12px;
    gap: 12px;
}

.dismiss-toast-button {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-500);
}

.undo-toast-button {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #000000;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    line-height: 18px;
    color: #333;
}

.toast-message-title {
    color: #000000;
    margin-bottom: 4px;
}

.toast-message-text {
    color: var(--color-gray-500);

}

.safe-icon {
    height: 20px;
    width: 20px;
    margin-top: 3px;
}

.close-button-column {
    width: 20px;
}

.close-button-toast svg {
    width: 20px;
    height: 20px;
}


.MuiButtonBase-root.close-button-toast {
    padding: 0;
}