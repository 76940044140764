.organization-section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.organization-title-section {
    margin-bottom: 20px;
}

.organization-section-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.logo-upload-section {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin: 16px 0px;
}

.uploaded-logo {
    max-width: 120px;
    height: auto;
    margin-bottom: auto;
    margin-top: auto;
}

.settings-input {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    border: 1px solid var(--color-gray-200);
    padding: 10px 14px;
    border-radius: 6px;
    height: 44px;
    margin-top: 16px;
    min-width: 512px;
}

.settings-input:hover,
.settings-input:focus {
    border-color: var(--color-gray-900);
    outline: none;
}

@media (max-width: 991px) {
    .settings-input {
        min-width: 100%;
    }
}