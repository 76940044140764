.MuiCard-root.discovery-card {
    margin-bottom: 16px;
    border: 1px solid #ddd;
    width: min(840px, 100%);
    cursor: pointer;
    position: relative;
    padding: 24px;
    box-shadow: 0 0 0 0;
    border-radius: 6px;
}

.discovery-card.selected {
    border: 1px solid var(--color-gray-900);
}

.discovery-card:hover {
    border: 1px solid var(--color-gray-500);
}

.discovery-card-content {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.discovery-card-copy {
    width: 100%;
}

.discovery-card-container {
    padding: 0 !important;
}

.discovery-logo {
    width: 32px;
    margin-right: 16px;
    margin-top: 4px;
}

.MuiRadio-colorPrimary.discovery-card-radio {
    position: absolute;
    top: 16px;
    right: 16px;
}

.discovery-card-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--color-gray-900);
    margin-bottom: 4px;
    margin-right: 40px;
}

.discovery-card-description {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-500);
    margin-bottom: 16px;
}

.discovery-card-additional-info {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--color-gray-500);
    margin-top: 16px;
}

.discovery-card-divider {
    width: 100%;
}

.discovery-card-additional-info-container {
    display: flex;
    justify-content: start;
    align-items: center;
}

.discovery-card-switch {
    margin-top: 16px;
}

.discovery-card-additional-info-link {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: var(--color-gray-900);
    text-decoration: underline;
}