.application-card {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-border);
  border-radius: 8px;
  overflow: hidden;
  min-width: 300px;
  max-width: 100%;
  transition: border-color 0.3s;
}

.application-card-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.application-card-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.application-card-status {
  margin-left: 8px;
  margin-bottom: 16px;
  height: 40px;
}

.application-card-status-single {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-gray-900);
}

.application-card-logo {
  height: 14px;
  width: 14px;
  margin-left: 0px;
  margin-right: 8px;
}

.application-card-logo {
  max-height: 22px;
  max-width: 22px;
  border-radius: 4px;
  margin-left: 8px;
}

.application-card-title {
  font-size: 16px;
  color: var(--color-gray-900);
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.application-card-subtitle {
  font-size: 16px;
  color: var(--color-gray-500);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.application-card-description {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 16px;
  color: var(--color-gray-500);
  border-top: 1px solid var(--color-border);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.button-icon {
  height: 16px;
  width: 16px;
}

.delete-button {
  width: 16px;
  cursor: pointer;
  padding-top: 10px;
}