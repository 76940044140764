.healthStatisticContent {
    margin-left: auto;
    margin-right: auto;
    height: 372px;
    display: flex;
    max-width: 410px;
    min-width: 310px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.healthPercentage {
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--color-gray-900);
}

.healthDescription {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--color-gray-500);
}

.statisticContainer {
    padding-top: 20px;
}

.halfDonutChartContainer {
    position: relative;
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chartContainer {
    position: relative;
    width: 100%;
    padding-bottom: 50%;
    height: 0;
    overflow: hidden;
}

.healthPercentageContainer {
    position: absolute;
    top: 100px;
    text-align: center;
    z-index: -1;
}

.healthLegendContainer {
    margin-top: 32px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.healthLegend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
}

.healthLegendItem {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 0px;
}

.healthButtonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    width: 100%;
}

.healthButton {
    text-transform: none;
    padding: 0;
}