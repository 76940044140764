.connection-status {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--color-gray-900);
}
