.link {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    text-decoration: none !important;
    color: var(--color-gray-900);
}

img.home-icon {
    height: 20px;
}

.breadcrumbs {
    margin-bottom: 16px;
}

li.MuiBreadcrumbs-separator.css-1wuw8dw-MuiBreadcrumbs-separator {
    margin-left: 16px;
    margin-right: 16px !important;
}