:root {
  --footer-color: #4b5057;
  --icon-height: 35px;
  --language-button-bg: var(--color-gray-100);
  --language-button-border: 1px solid var(--color-gray-200);
  --language-button-radius: 4px;
  --language-menu-bg: var(--color-gray-100);
  --language-menu-border: 1px solid var(--color-gray-200);
  --language-menu-radius: 6px;
  --language-menu-hover-bg: var(--color-gray-200);
  --font-weight: 500;
  --transition-duration: 0.3s;
  --menu-width: 120px;
}

.languageSelector .MuiButton-root.languageButton {
  background-color: var(--language-button-bg);
  color: black;
  border: var(--language-button-border);
  border-radius: var(--language-menu-radius);
  text-transform: capitalize;
  margin-right: 8px;
  width: var(--menu-width);
  font-size: 12px;
  font-weight: var(--font-weight);
  min-width: 0 !important;
}

.languageSelector .MuiButton-root.languageButton.active {
  background-color: var(--language-button-bg);
}

.languageSelector {
  right: 20px;
  bottom: 80px;
}

.languageMenu {
  box-shadow: none;
}

li.MuiMenuItem-root.languageMenuItem:first-child {
  border-top-left-radius: var(--language-menu-radius);
  border-top-right-radius: var(--language-menu-radius);
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom: none;
}

li.MuiMenuItem-root.languageMenuItem:last-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: var(--language-menu-radius);
  border-bottom-left-radius: var(--language-menu-radius);
  border-top: none;
}

li.MuiMenuItem-root.languageMenuItem {
  border: var(--language-menu-border);
  border-radius: var(--language-menu-radius);
  background-color: var(--language-menu-bg);
  height: 34px;
  line-height: 24px;
  text-align: left;
  text-transform: capitalize;
  width: var(--menu-width);
  font-size: 12px;
  font-weight: var(--font-weight);
}

li.MuiMenuItem-root.languageMenuItem:hover {
  background-color: var(--language-menu-hover-bg);
}

.icon,
.rotateIcon {
  transition: transform var(--transition-duration);
}

.rotateIcon {
  transform: rotate(180deg);
}