.get-started-container {
    width: 402px;
    background-color: var(--color-gray-50);
    border: 1px solid var(--color-border);
    border-radius: 6px;
    padding: 24px;
    box-sizing: border-box;
}

.get-started-container.dismissable {
    min-height: 464px;
    width: 100%;
}

@media (max-width: 991px) {
    .get-started-container {
        width: 100% !important;
        height: 100%;
    }
}

.get-started-header-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    justify-content: space-between;
}

.get-started-header {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    margin-right: 8px;
}

.get-started-counter {
    color: var(--color-gray-500);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.get-started-tasks {
    border: 1px solid var(--color-border);
    border-radius: 6px;
}

.task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    background-color: #fff;
    padding: 16px;
    cursor: pointer;
    border-bottom: 1px solid var(--color-border);
}

.task:last-child {
    margin-bottom: 0;
    border-bottom: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.task:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.task-checkbox {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
}

.task-checkbox.completed {
    border: 0px solid;
}

.task-text {
    font-size: 14px;
    line-height: 20px;
    color: var(--color-gray-500);
    text-align: left;
    flex: 1;
}

.task-text.completed {
    color: var(--color-gray-900);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.task-left {
    display: flex;
    align-items: center;
}

.task-chevron {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}