/* Global Styles */
.policy-dropdown.closed .toggle-header {
    padding: 20px;
}

.policy-dropdown {
    /* padding: 20px 20px 0px 20px; */
    background: white;
    border: 1px solid #ccc;
    border-radius: 8px;
}


/* Toggle Switch */
.toggle-header {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 20px;
}

.toggle-header.no-hover {
    cursor: default;
}

.noHover {
    pointer-events: none;
    cursor: default !important;
}

.toggle-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    margin-left: 12px;
    cursor: pointer;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.toggle-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-left: 12px;
    cursor: pointer;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-500);
}

.policy-description {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-500);
}

.toggle-arrow {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
}

.toggle-arrow.open {
    transform: rotate(90deg);
}


.toggle-content-wrapper {
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
}

.toggle-content-closed {
    max-height: 0;
    opacity: 0;
}

.toggle-content-open {
    opacity: 1;
    padding: 0px 20px 20px 20px;
}


/* Risk Period Input */
.risk-period-input label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 4px;
}

.risk-period-input input {
    width: 60px;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 32px;
    margin-top: auto;
    margin-bottom: auto;

}

/* Validation Action */
.validation-action label {
    display: block;
    font-size: 14px;
    font-weight: 600;
}

.validation-action input[type="radio"] {
    margin-right: 8px;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    accent-color: var(--color-gray-900);
}

.validation-action-div {
    display: flex;
    align-items: start;
    border: 1px solid #D9DCE0;
    border-radius: 6px;
    width: 100%;
    padding: 10px;
    cursor: pointer;
}

.validation-action-container {
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 50%;
    gap: 8px;
    margin-bottom: 16px;
    margin-top: 16px;
}

/* Additional Settings */

.additional-settings label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
}

.additional-settings input {
    margin-right: 8px;
    cursor: pointer;
}

.additional-settings-container {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
}

.additional-settings-label {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    cursor: pointer;
}

/* Save Button */
.save-button {
    display: block;
    width: 100%;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;
}

.save-button:hover {
    background-color: #0056b3;
}

/* Responsiveness */
@media (max-width: 600px) {
    .os-validation-settings {
        padding: 15px;
    }

    .toggle-title {
        font-size: 14px;
    }

    .save-button {
        font-size: 14px;
        padding: 10px;
    }
}

.additional-settings-content {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    width: 50%;
}

@media (max-width: 1300px) {
    .validation-action-container {
        flex-direction: column;
        width: 100%;
        margin-top: 0px;
    }

    .settings-section.mobile {
        flex-direction: column !important;
    }

    .additional-settings-content {
        margin-top: 0px;
        margin-bottom: 16px;
    }

    .organization-title-section {
        margin-bottom: 8px;
    }
}

.validation-action-div label {
    margin-left: 8px;
    font-size: 14px;
    cursor: pointer;
}

.warn-line {
    display: flex;
    align-items: center;
    margin-left: 44px;
}

.warning-icon,
.block-icon {
    font-size: 18px;
    margin-right: 8px;
}

.warn-text,
.block-text {
    margin-right: 4px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: var(--color-gray-700)
}

.warn-text.disabled {
    color: var(--color-gray-400);
}

.warn-text-bold,
.block-text-bold {
    font-weight: 700;
}


.warn-input,
.block-input {
    width: 50px;
    align-content: center;
    padding: 4px;
    font-size: 14px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 8px;
}

input[type=number] {
    text-align: center;
}

.warn-person-section {
    cursor: pointer;
}