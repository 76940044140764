.button {
    padding: 10px;
}

.table .policies-table th:nth-child(1),
.table .policies-table td:nth-child(1) {
    width: 300px;
}

.table .policies-table th:nth-child(2),
.table .policies-table td:nth-child(2) {
    width: 100%;
    white-space: normal;
    overflow: visible;
}

.table .policies-table th:nth-child(3),
.table .policies-table td:nth-child(3) {
    width: 80px;
}