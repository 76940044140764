.sticky-footer {
    display: flex;
    bottom: 0;
    height: 100px;
    background-color: var(--color-gray-50);
    padding: 16px 32px;
    border-top: 1px solid #ddd;
    justify-content: space-between;
    position: fixed;
    width: min(480px, 100vw);
    z-index: 1000;
    flex-shrink: 0;
}

.sticky-footer-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-900);
}

.sticky-footer-status {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: var(--color-gray-700);
}