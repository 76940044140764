.discovery-overview-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-self: start;
}

@media (max-width: 991px) {
    .discovery-overview-header {
        flex-direction: column;
        gap: 8px;
    }
}

.discovery-highlights-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: var(--color-gray-900);
}