.rolePicker .MuiListItemButton-root.Mui-selected {
  background-color: var(--color-gray-300);
}
.rolePicker .MuiListItemButton-root.Mui-selected:hover {
  background-color: var(--color-gray-300);
}
.rolePicker .MuiListItemButton-root:hover {
  background-color: var(--color-gray-300);
}
.rolePicker .MuiDivider-root {
  border-color: var(--color-gray-300);
}

.rolePicker {
  margin-left: 8px;
  margin-right: 8px;
}

.rolePicker .organizationName {
  margin: 0px;
}

.rolePicker .organizationName .MuiListItemText-primary {
  font-weight: 700;
  line-height: 24px;
  font-size: 16px;
  color: var(--color-gray-900);
}

.rolePicker .roleName {
  font-weight: 400;
  font-size: 12px;
  color: var(--color-gray-500);
}
