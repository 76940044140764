.policy-page {
    display: flex;
    gap: 16px;
    width: 100%;
    margin-top: 16px;
    justify-content: "space-between";
}

.policy-rules {
    width: 60%;
}

.policy-integrations {
    width: 40%;
}

@media screen and (max-width: 1300px) {
    .policy-page {
        flex-direction: column;
    }

    .policy-rules {
        width: 100%;
    }

    .policy-integrations {
        width: 100%;
    }

}