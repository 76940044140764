.sidebar-active-devices {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: var(--color-gray-700);
}

.sidebar-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-900);
}