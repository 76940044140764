.upload-container {
    border: 1px solid var(--color-border);
    border-radius: 6px;
    width: 360px;
    height: 96px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: border-color 0.3s ease;
}

.upload-container:hover, .upload-container.dragging {
    border: 1px solid var(--color-gray-900);
}

.upload-container.error {
    border: 2px solid var(--color-red);
}

.upload-container.disabled {
    background-color: #f9fafb;
    cursor: not-allowed;
    pointer-events: none;
}

.upload-input {
    display: none;
}

.upload-content {
    pointer-events: none;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.upload-icon {
    height: 44px;
    width: 44px;
    margin-right: 16px;
}

.upload-text {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: var(--color-gray-900);
}

.upload-text strong {
    font-weight: 700;
}

.upload-hint {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: var(--color-gray-500);
}