.operatingSystemsStatisticContent {
    height: 372px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.operatingSystemsPercentage {
    font-size: 48px;
    font-weight: 500;
    line-height: 60px;
    letter-spacing: -0.02em;
    text-align: center;
    color: var(--color-gray-900);
}

.operatingSystemsDescription {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--color-gray-500);
}

.statisticContainer {
    padding-top: 20px;
}

.donutChartContainer {
    position: relative;
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.osChartContainer {
    position: relative;
    width: 100%;
    height: 240px;
    overflow: hidden;
}

.operatingSystemsPercentageContainer {
    position: absolute;
    top: 80px;
    text-align: center;
    z-index: -1;
}

.operatingSystemsLegendContainer {
    margin-top: 32px;
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.operatingSystemsLegend {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    max-height: 4.5em;
    overflow: hidden;
}

.operatingSystemsLegendItem {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 0px;
}

.operatingSystemsButtonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    width: 100%;
}

.operatingSystemsButton {
    text-transform: none;
    padding: 0;
}